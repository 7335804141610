<template>
  <div class="activity-rules">
    <div class="container" v-html="text">
    </div>
  </div>
</template>
<script>
import { GetActInfoAPI,UserInviteInfoAPI } from '../api/api'
export default{
  data() {
    return {
      text:''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      const {data:{user_invite_act_id}} = await UserInviteInfoAPI()
      const { data:{ act_info}} = await GetActInfoAPI({type:user_invite_act_id})
      this.text = act_info.rule;
    },
  },
}
</script>
<style lang="scss" scoped>
  .activity-rules{
    min-height: 80vh;
    padding: 10px;
    /deep/ .container{
      word-break:break-all;
      word-wrap:break-word;
    }
  }
</style>